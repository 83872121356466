import * as React from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({
  lang,
  title,
  description,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      description={metaDescription}
    >
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
      <meta name="description" content="metaDescription" />
      <meta name="keywords" content={site.siteMetadata.keyword} />
      <meta name="og:title" content="title || site.siteMetadata.title" />
      <meta name="og:description" content={site.siteMetadata.description} />
      <link rel="canonical" href={site.siteMetadata.siteUrl} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content="title" />
      <meta name="twitter:description" content="metaDescription" />
      {/* RSS feed */}
      <link
        rel="alternate"
        type="application/rss+xml"
        title="RSS Feed for assutech"
        href="/feed.xml"
      />
      <meta name="msapplication-TileColor" content="#663399" />
      <meta name="theme-color" content="#663399" />
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: 'en',
  description: '',
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Seo;
