import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';

import facebook from '../../images/facebook-f.svg';
import twitter from '../../images/twitter.svg';
import linkedin from '../../images/linkedin.svg';

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
     logo: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED width: 150 , height: 30)
        }   
      }
    }
  `);

  return (
    <footer className="l:pt-80 w-full flex flex-wrap pt-150px bg-footer-pattern bg-body bg-no-repeat bg-90% absolute text-secondary font-futura sm:p-6">
      <div className="md:container xl:px-[3.3rem] px-4 mx-0 md:mx-auto pt-4 sm:px-3">
        <div className="grid lg:grid-cols-3 gap-4">
          <div className="col-span-1">
            <figure className="block mb-25px mt-4 w-full">
              <GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="logo" />
            </figure>
            <p className="m-0 pr-3xl">Assutech is Teamwork. Goals. Results.</p>
            <br />
            <p className="m-0 pr-3xl">
              Nonetheless, we do have our fair share of fun.
              The space is always open for company and hot drinks,
              plus, if you&apos;re feeling lucky,
              <a className="text-white mr-2 ml-2" href="https://6or2r14668f.typeform.com/to/avS0LqD6" aria-label="office culture" target="_blank" rel="noreferrer">challenge </a>
              the team to a PS5 game of FIFA. May the odds ever be in your favor 😉
            </p>
          </div>

          <div className="col-span-1">
            <h3 className="text-md-1 font-bold mt-4 mb-md">EXPLORE</h3>
            <ul className="m-0 p-0 w-50% float-left">
              <li className="list-none my-2px py-2px before:bg-bold before:content before:float-left before:h-5px before:mr-3 before:mt-3 before:w-5px before:rounded-lg text-secondary hover:text-[#ffcdc8]"><Link to="/" alt="home" rel="preload" className=" no-underline">Home</Link></li>
              <li className="list-none my-2px py-2px before:bg-bold before:content before:float-left before:h-5px before:mr-3 before:mt-3 before:w-5px before:rounded-lg text-secondary hover:text-[#ffcdc8]"><Link to="/TechTalks" alt="tech talks" rel="preload" className="no-underline">Blog</Link></li>
              <li className="list-none my-2px py-2px before:bg-bold before:content before:float-left before:h-5px before:mr-3 before:mt-3 before:w-5px before:rounded-lg text-secondary hover:text-[#ffcdc8]"><Link to="/Team" alt="team" rel="preload" className="no-underline">Team</Link></li>
              <li className="list-none my-2px py-2px before:bg-bold before:content before:float-left before:h-5px before:mr-3 before:mt-3 before:w-5px before:rounded-lg text-secondary hover:text-[#ffcdc8]"><a target="_blank" rel="noreferrer" aria-label="go digital" href="https://vitamin2.typeform.com/to/zNyk8z" className="no-underline">Go Digital</a></li>
            </ul>
            <ul className="float-left m-0 p-0 w-50%">
              <li className="list-none my-2px py-2px before:bg-bold before:content before:float-left before:h-5px before:mr-3 before:mt-3 before:w-5px before:rounded-lg text-secondary hover:text-[#ffcdc8]"><a target="_blank" rel="noreferrer" aria-label="assutech" href="https://6or2r14668f.typeform.com/to/fBsPkegC" className="no-underline">Join Assutech</a></li>
              <li className="list-none my-2px py-2px before:bg-bold before:content before:float-left before:h-5px before:mr-3 before:mt-3 before:w-5px before:rounded-lg text-secondary hover:text-[#ffcdc8]"><a target="_blank" rel="noreferrer" aria-label="internships" href="https://6or2r14668f.typeform.com/to/e3YLylYe" className="no-underline">Internships</a></li>
              <li className="list-none my-2px py-2px before:bg-bold before:content before:float-left before:h-5px before:mr-3 before:mt-3 before:w-5px before:rounded-lg text-secondary hover:text-[#ffcdc8]"><a target="_blank" rel="noreferrer" aria-label="contact" href="https://6or2r14668f.typeform.com/to/w1OmeNTn" className="no-underline">Contact Us</a></li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="md:mb-md md:mt-md mb-md text-md-1 font-bold">REACH US</h3>
            <address className="block font-light leading-inherit mb-5 w-full">
              <a target="_blank" rel="noreferrer" aria-label="location" href="https://goo.gl/maps/q8okTUDnpRaZc6g9A" className="text-secondary underline">
                7 - 8 Madiba Mall,
                <br />
                Brufut Gardens Estate
                <br />
                <strong>The Gambia, West Africa</strong>
              </a>
            </address>
            <a className="mr-2 hover:text-white text-secondary underline" aria-label="contact" href="tel:+2207935827‬">+220 3814760‬</a>
            |
            <a className="ml-2 hover:text-white text-secondary underline" aria-label="email" href="mailto:info@assutech.gm">info@assutech.gm</a>
            <ol className="block mt-5 p-0 w-full md-mb-0">
              <li className="inline-block p-0 mr-3">
                <a target="_blank" rel="noreferrer" aria-label="facebook" href="https://www.facebook.com/assutech" className="text-secondary text-16px ">
                  <img src={facebook} alt="facebook" className="transition ease-in-out hover:scale-[1.09]" />
                </a>
              </li>
              <li className="inline-block p-0 mr-3">
                <a target="_blank" rel="noreferrer" aria-label="Twitter" href="https://www.twitter.com/assutech" className="text-secondary text-16px">
                  <img src={twitter} alt="twitter" className="transition ease-in-out hover:scale-[1.09]" />
                </a>
              </li>
              <li className="inline-block p-0 mr-3">
                <a target="_blank" rel="noreferrer" aria-label="linkedin" href="https://www.linkedin.com/company/assutech-limited/" className="text-secondary text-16px">
                  <img src={linkedin} alt="linkedin" className="transition ease-in-out hover:scale-[1.09]" />
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="md:flex flex-wrap text-md mt-70px py-wide w-full border-t-solid border-t-1 border-t-[#2b2b2c]">
        <div className="md:container xl:px-[3.3rem] px-4 mx-0 md:mx-auto pt-1rem sm:px-3">
          <span className="float-left">
            ©&nbsp;
            {new Date().getFullYear()}
            &nbsp;Assutech Inc.
          </span>
          <b className="float-right font-normal">All Rights Reserved.</b>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
