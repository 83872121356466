import React, { useState } from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import './header.style.css';

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED width: 150 , height: 30)
        }
      },
      contentfulNavigation {
        navLink {
          name
          link
        }
      }
    }
  `);
  const [openMenu, setOpenMenu] = useState(false);
  const links = data.contentfulNavigation.navLink;

  return (
    <div>
      <div className={openMenu ? 'site-navigation active' : 'site-navigation'}>
        <div className="layers w-full h-full absolute left-0 top-0 overflow-hidden">
          <span className="h-0 absolute bottom-0 bg-body w-33" />
          <span className="h-0 absolute bottom-0 bg-body w-33" />
          <span className="h-0 absolute bottom-0 bg-body w-33" />
        </div>
        <div className="inner relative translate-y-negative-50 delay-65 opacity- z-2">
          <ul className="grid m-0 p-0 lg:hidden">
            {links.map(({
              name,
              link,
            }) => (
              <li key={name} className="inline-block mx-25px py-10px list-none relative hover:visible hover:opacity-1"><Link className="font-futura no-underline" to={link} rel="preload">{name}</Link></li>
            ))}
            <li className="mx-25px inline-block list-none relative"><a className="uppercase no-underline bg-bold shadow-lg text-primary inline-block text-md font-bold h-4 leading-lg px-wide translate-y-50 delay-1.4 rounded-lg" rel="noreferrer" target="_blank" aria-label="go digital" href="https://vitamin2.typeform.com/to/zNyk8z">START NOW</a></li>
          </ul>
        </div>
      </div>
      <nav className="navbar w-full m-h-50px bg-primary 2md:flex flex-wrap py-lg px-15px 2md:!absolute fixed left-0 top-0 z-15 pt-4 sm:px-3">
        <div className="md:container xl:px-[3rem] flex items-center justify-between mx-0 md:mx-auto pt-4 sm:px-3">
          <div className="logo ml-0 mr-auto z-1">
            <Link to="/" rel="preload" className="inline-block">
              <GatsbyImage className="h-[30px] mb-7px" image={data.file.childImageSharp.gatsbyImageData} alt="assutech logo" />
            </Link>
          </div>

          <div className="site-menu ml-auto mr-0">
            <button type="button" onClick={() => setOpenMenu(!openMenu)} className="hamburger-menu" aria-label="hambuger-main">
              <div className={openMenu ? 'hamburger open active' : 'hamburger'}>
                <span aria-label="hambuger-span1" />
                <span aria-label="hambuger-span2" />
                <span aria-label="hambuger-span3" />
              </div>
            </button>
            <ul className="2md:flex flex-wrap ml-auto mr-0 mt-1 hidden">
              {links.map(({
                name,
                link,
              }) => (
                <li key={name} className="m-0 ml-6 p-0 list-none relative "><Link className="font-futura no-underline text-secondary hover:text-[#ffcdc8] text-[0.9rem] inline-block font-semibold border-t-1px border-t-solid border-t-transparent" rel="preload" to={link}>{name}</Link></li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
